import styles from './Video.module.scss';

import { Fragment } from 'react';
import { useNonce } from '@/utilities/contexts/Nonce';
import cn from 'classnames';
import DOMPurify from 'isomorphic-dompurify';
import Script from 'next/script';
import typeVideo from '@/utilities/types/Video';

interface VideoProps {
  className?: string;
  video: typeVideo[];
}

const Video = (props: VideoProps) => {
  const { className, video } = props;

  const nonce = useNonce();

  return (
    <div className={cn(className, styles.root)}>
      {video.map((video, index) => (
        <Fragment key={index}>
          {video.wistia && video.wistia.id && (
            <div className={styles.wistia}>
              <Script
                src={`//fast.wistia.com/embed/medias/${video.wistia.id}.jsonp`}
                nonce={nonce}
              />
              <Script
                id="wistia"
                src="//fast.wistia.com/assets/external/E-v1.js"
                nonce={nonce}
              />
              <div className="wistia_responsive_padding">
                <div className="wistia_responsive_wrapper">
                  <div
                    className={`wistia_embed wistia_async_${video.wistia.id} seo=false videoFoam=true`}
                  />
                </div>
              </div>
            </div>
          )}
          {video.youtube && video.youtube.id && (
            <div
              className={styles.youtube}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  `<iframe src="https://www.youtube.com/embed/${video.youtube.id}?feature=oembed&amp;controls=0&amp;hd=1&amp;autohide=1"></iframe>`,
                  { ADD_TAGS: ['iframe'] },
                ),
              }}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Video;
